<template>
  <div class="table">
    <!-- 头部 -->
    <div class="crumbs">
      <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
    </div>
    <!-- 表格内容 -->
    <div class="container">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="配置名称:" label-width="220">
          <el-input v-model="queryParams.name" placeholder="请输入配置名称" clearable></el-input>
        </el-form-item>
        <div style="float: right">
          <el-button type="primary" @click="changeQuery()">搜索</el-button>
        </div>
      </el-form>
      <div class="action">
        <el-button type="primary" @click="add()">新建积分配置</el-button>

      </div>
      <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
        <el-table-column prop="id" label="id" align="center" width="100"></el-table-column>
        <el-table-column prop="title" label="配置名称" align="center" width="auto"></el-table-column>
        <el-table-column prop="subtitle" label="抵扣" align="center" width="auto">
            <template slot-scope="scope">
                {{scope.row.deduct_trans_rate}}积分抵扣1元，抵扣上限，毛利{{scope.row.deduct_rate_limit}} %
            </template>
        </el-table-column>
          <el-table-column prop="subtitle" label="抽奖" align="center" width="auto">
              <template slot-scope="scope">
                  {{scope.row.luckydraw_trans_rate}}积分参与一次，每{{scope.row.luckydraw_day_range}}天参与{{ scope.row.luckydraw_range_limit}} 次
              </template>
          </el-table-column>
          <el-table-column prop="subtitle" label="兑换" align="center" width="auto">
              <template slot-scope="scope">
                  {{scope.row.integral_trans_num}}  积分兑换 {{scope.row.card_trans_num }}张月卡
              </template>
          </el-table-column>
          <el-table-column prop="subtitle" label="购物" align="center" width="auto">
              <template slot-scope="scope">
                  订单金额满 {{form.buy_over_amount}} 元，{{form.buy_give_integral}} %，赠送积分（积分数按金额比例抵扣转换）
              </template>
          </el-table-column>
        <el-table-column label="排序" prop="r" align="center" width="100"></el-table-column>
        <el-table-column prop="start_day" label="上线时间" width="200" align="center"></el-table-column>
        <el-table-column prop="end_day" label="下线时间" width="200" align="center"></el-table-column>
        <el-table-column label="状态" prop="state" align="center" width="100">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="scope.row.state === 1">未开始</el-tag>
            <el-tag type="success" v-if="scope.row.state === 2">活动中</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="100">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
            <el-button v-if="scope.row.state === 2 " type="text" class="red" @click="state(scope.row, 1)">下线
            </el-button>
            <el-button v-if="scope.row.state === 1 " type="text" class="green" @click="state(scope.row, 2)">
              上线</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
    </div>
  </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {
  request
} from '@/common/request';
import {
  deal
} from '@/common/main';
export default {
  name: 'seller',
  components: {
    Breadcrumb,
    Pagination
  },
  data() {
    return {

      defaultImg: require('../../assets/img/zhan.jpeg'),
      hearder: {},
      menuList: ['营销', '积分活动管理'],
      queryParams: {
        searchName: '',
        page: 1,
        pagesize: 10
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      positionList: [],
      loading: false,
      pageInfo: {},
      addVisible: false,
      title: '加载中',
      mode: 'add',
      form: {
        state: 1
      },
    };
  },
  created() {
    this.hearder.token = localStorage.getItem('token');
    this.hearder.platform = 'admin';
    deal.getconf('seckill', this)
    this.loadData(); // 这是我们获取数据的函数
  },
  activated() {
    this.loadData();
  },
  beforeRouteLeave(to, from, next) {
    // 设置下一个路由的 meta
    to.meta.keepAlive = false; // B 跳转到 A 时，让 A 不缓存，即刷新（代码写在B页面）
    next();
  },
  methods: {


    loadData() {
      this.loading = true;
      // 获取秒杀专场列表
      request.get('/system/integralConfig/list', this.queryParams).then(ret => {
        if (ret.code == 1) {
          this.loading = false;
          this.pageInfo = ret.data;
          this.pageInfo.list = ret.data.list;
          this.pageInfo.list.map(function (val) {
            val.start_day = deal.timestamp(val.start_day, 'unix');
            val.end_day = deal.timestamp(val.end_day, 'unix');
            return val;
          })
        } else {
          this.$message.error(ret.msg);
        }
      });
    },
    // 秒杀专场商品
    goProduct(index, row) {
      this.$router.push({ path: '/activity-SeckillSessionGoodsList', query: { id: row.id } })
    },
    add() {
      this.$router.push({ path: '/activity-IntegralConfigAdd' })
    },
    edit(index, row) {
      this.title = '编辑';
      this.$router.push({ path: '/activity-IntegralConfigEdit', query: { id: row.id } })
    },
    changeQuery() {
      this.queryParams.page = 1;
      this.loadData();
    },
    refreshPageSize(val) {
      this.queryParams.pagesize = val;
      this.loadData();
    },
    refreshPageNum(val) {
      this.queryParams.page = val;
      this.loadData();
    },
    // 单个修改状态
    state(item, state) {
      let id = item.id;
      let param = Object.assign({}, item);
      param.state = state;
      param.start_day = deal.timestamp(param.start_day);
      param.end_day = deal.timestamp(param.end_day);
      request.post('/system/integralConfig/list', param).then(ret => {
        if (ret.code == 1) {
          this.$message.success('操作成功');
          this.loadData();
        } else {
          this.$message.error(ret.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.green {
  color: green;
}

.mr10 {
  margin-right: 10px;
}

.crop-demo-btn {
  position: absolute;
  right: 0px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #409eff;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  box-sizing: border-box;
  float: right;
}

.crop-input {
  position: absolute;
  width: 100px;
  height: 40px;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.pre-img {
  width: 100px;
  height: 100px;
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 5px;
  margin-top: 10px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  font-size: 28px;
  line-height: 100px;
}

/* .avatar-uploader .avatar-uploader-icon {
        line-height: 100px;
    } */

.cell {
  text-align: center;
}

.el-input--suffix .el-input__inner {
  padding-right: 15px;
}

.up {
  margin-top: 20px;
}
</style>